import { Flex } from '@chakra-ui/react'
import { NextPage } from 'next'

import { PreferredBrowserTopbar } from 'gamma_components/PreferredBrowserTopbar'

type BaseLayoutProps = {
  children: React.ReactNode
  withFooter?: boolean
  hidePreferredBrowserTopbar?: boolean
}

export const BaseLayout = ({
  children,
  withFooter,
  hidePreferredBrowserTopbar,
}: BaseLayoutProps): JSX.Element => {
  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      minHeight="var(--100vh)"
      bgColor="gray.100"
      color="gray.800"
    >
      {!hidePreferredBrowserTopbar && <PreferredBrowserTopbar />}
      <Flex
        zIndex="1"
        id="main"
        flex="1"
        justifyContent="center"
        alignItems="center"
        direction="column"
        position="relative"
      >
        {children}
      </Flex>
      {withFooter && (
        <Flex
          fontSize="12px"
          color="gray.400"
          pb={8}
          id="footer"
          justifyContent="center"
        >
          &copy; {new Date().getFullYear()} Gamma Tech, Inc.
        </Flex>
      )}
    </Flex>
  )
}

export function withBaseLayout<T>(
  Component: NextPage<T>,
  options: {
    withFooter?: boolean
    hidePreferredBrowserTopbar?: boolean
  } = { withFooter: false, hidePreferredBrowserTopbar: false }
) {
  const { withFooter, hidePreferredBrowserTopbar } = options
  const WithLayoutComponent = (props: T) => (
    <BaseLayout
      withFooter={withFooter}
      hidePreferredBrowserTopbar={hidePreferredBrowserTopbar}
    >
      <Component {...props} />
    </BaseLayout>
  )

  if ('getInitialProps' in Component) {
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}
