import { config } from 'config'

// Yanked from
// https://github.com/vercel/next.js/tree/297459d534e5d88b5bdaa1baa1e03f5eea142c12/examples/with-google-analytics
export const GA_TRACKING_ID: string = config.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtagPageview = (url) => {
  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({ action, category, label, value }) => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
