/**
 * NB: This should mirror the footer on the Marketing site. That's why the fonts are specified
 * in px units
 */
import {
  Center,
  Stack,
  Container,
  Flex,
  SimpleGrid,
  Box,
  Image,
  ListItem,
  UnorderedList,
  Heading,
  Link,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { launchIntercomUI } from 'modules/intercom'
import { setIsIntercomOpen } from 'modules/intercom/reducer'

import { MARKETING_SITE_LINKS } from '../constants'

const getFooterLinks = (handleContactUsClick) => {
  return [
    {
      category: 'Product',
      items: [
        {
          text: 'FAQ',
          url: MARKETING_SITE_LINKS.faq,
        },
        {
          text: 'Change log',
          url: MARKETING_SITE_LINKS.changeLog,
        },
        {
          text: 'Pricing',
          url: MARKETING_SITE_LINKS.pricing,
        },
        {
          text: 'Templates',
          url: MARKETING_SITE_LINKS.templates,
        },
        {
          text: 'Inspiration',
          url: MARKETING_SITE_LINKS.inspiration,
        },
        {
          text: 'Contact us',
          onClick: handleContactUsClick,
        },
      ],
    },
    {
      category: 'Company',
      items: [
        {
          text: 'Team',
          url: MARKETING_SITE_LINKS.team,
        },
        {
          text: 'Terms of Service',
          url: MARKETING_SITE_LINKS.terms,
        },
        {
          text: 'Privacy Policy',
          url: MARKETING_SITE_LINKS.privacy,
        },
      ],
    },
    {
      category: 'Follow us',
      items: [
        {
          text: 'Twitter',
          url: 'https://twitter.com/meetgamma',
        },
        {
          text: 'Instagram',
          url: 'https://www.instagram.com/meetgamma/',
        },
        {
          text: 'LinkedIn',
          url: 'https://www.linkedin.com/company/gamma-app/',
        },
        {
          text: 'TikTok',
          url: 'https://www.tiktok.com/@meetgamma',
        },
      ],
    },
  ]
}
export const MarketingFooter = () => {
  const dispatch = useDispatch()

  // We need to do this owing to https://github.com/gamma-app/gamma/blob/2db66fb0a2854a5bd0dfa990df88352c3c2b5c92/packages/client/src/modules/intercom/IntercomScript.tsx#L31-L35
  const handleContactUsClick = useCallback(() => {
    launchIntercomUI()
    dispatch(setIsIntercomOpen({ isOpen: true }))
  }, [dispatch])

  const footerLinks = getFooterLinks(handleContactUsClick)

  return (
    <Flex
      fontSize="12px"
      bgColor="#1d0042"
      color="gray.400"
      py={20}
      id="footer"
      justifyContent="center"
    >
      <Container maxW="940px">
        <Stack>
          <SimpleGrid columns={4} spacing={6} mb={10} minChildWidth="200px">
            <Box w="100%">
              <Link href="/">
                <Image
                  src="https://assets.website-files.com/60de2701a7b28f308f619d3d/60de2701a7b28ff7e9619d49_White%20Logo%20g%20spot.svg"
                  loading="lazy"
                  width="120px"
                  alt="Gamma logo"
                />
              </Link>
            </Box>
            {footerLinks.map(({ category, items }, index) => {
              return (
                <Box w="100%" key={index}>
                  <Heading
                    marginBottom="25px"
                    color="rgba(239, 208, 255, 0.7)"
                    fontWeight="bold"
                    fontSize="14px"
                    fontFamily="var(--body-font)"
                    letterSpacing="normal"
                  >
                    {category}
                  </Heading>
                  <UnorderedList listStyleType="none" margin={0}>
                    {items.map(({ text, url, onClick }, index) => {
                      return (
                        <ListItem fontSize="14px" key={index} pb={4}>
                          <Link
                            color="hsla(0, 0%, 100%, 0.86)"
                            href={url ? url : undefined}
                            onClick={onClick ? onClick : undefined}
                          >
                            {text}
                          </Link>
                        </ListItem>
                      )
                    })}
                  </UnorderedList>
                </Box>
              )
            })}
          </SimpleGrid>
          <Center color="rgba(239, 208, 255, 0.7)" fontSize="13px">
            &copy; {new Date().getFullYear()} Gamma Tech, Inc.
          </Center>
        </Stack>
      </Container>
    </Flex>
  )
}
