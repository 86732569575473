export const MARKETING_CONTAINER_MAX_WIDTH = '940px'

export const MARKETING_SITE_LINKS = {
  pricing: '/pricing',
  faq: 'https://gamma.app/public/Frequently-Asked-Questions-FAQ-o2he6ual8noal7u',
  changeLog: 'https://gamma.app/public/Change-Log-awopg3inl2eo3b3',
  team: 'https://gamma.app/public/Meet-the-Team-at-Gamma-nbqgqy05eyfcsbi',
  terms: 'https://gamma.app/terms',
  privacy: 'https://gamma.app/privacy',
  login: '/signin',
  signup: '/signup',
  templates: '/templates',
  inspiration: '/inspiration',
}

export const MARKETING_HEADER_HEIGHT = 16
