// 1. Import `extendTheme`
import {
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from '@chakra-ui/react'
import { gammaTheme } from '@gamma-app/ui'

// 2. Call `extendTheme` and pass your custom values
export const overrides: ThemeOverride = {
  colors: gammaTheme.colors,
  zIndices: {
    tooltip: 5600, // Force this to be higher than the toast manager
    modal: 1800, // Force this to go over popovers
    dropdown: 1600, // Force this to go over popovers
  },
  fonts: {
    body: "'Eudoxus Sans', sans-serif",
    heading: "'p22-mackinac-pro', sans-serif",
  },
  fontSizes: {
    xxs: '11px',
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: 'none',
      },
    },
    Button: {
      variants: {
        fancy: {
          color: 'white',
          borderRadius: 'full',
          background:
            'linear-gradient(180deg, #6850FF 0%, rgba(38, 59, 227, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(272.9deg, #003DD9 0.18%, #6850FF 109.19%, #DF7A6C 188.8%)',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          _hover: {
            background:
              'linear-gradient(180deg, #3A24C2 0%, rgba(38, 59, 227, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(272.9deg, #002C9D 0.18%, #6850FF 109.19%, #DF7A6C 188.8%, #A56057 224.56%)',
          },
          _active: {
            boxShadow: 'inset 1px 1px 5px rgb(0 0 0 / 50%)',
            background:
              'linear-gradient(180deg, #3A24C2 0%, rgba(38, 59, 227, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(272.9deg, #002C9D 0.18%, #6850FF 109.19%, #DF7A6C 188.8%, #A56057 224.56%)',
          },
          textDecoration: 'none !important',
        },
      },
    },
    Heading: {
      baseStyle: {
        letterSpacing: '-0.04em',
      },
      variants: {
        fancy: {
          backgroundImage: gammaTheme.colors.gradient['blue-to-purple'],
          backgroundClip: 'text',
          color: 'transparent',
        },
      },
    },
  },
}

export const marketingTheme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: 'trueblue' })
)
