/**
 * NB: This should mirror the header on the Marketing site. That's why the fonts are specified
 * in px units
 */
import { Box, Button, Image, Link, Spacer, Stack } from '@chakra-ui/react'

import { useUserContext } from 'modules/user'
import gammaLogo from 'publicImages/gamma-logo-white.svg'

import { MARKETING_HEADER_HEIGHT, MARKETING_SITE_LINKS } from '../constants'

const HEADER_LINKS = [
  {
    text: 'Pricing',
    url: MARKETING_SITE_LINKS.pricing,
    hideWhenSmall: true,
  },
  {
    text: 'Templates',
    url: MARKETING_SITE_LINKS.templates,
    hideWhenSmall: true,
  },
  {
    text: 'Login',
    url: MARKETING_SITE_LINKS.login,
  },
]

export type HeaderMode = 'dark' | 'light'

export const MarketingHeader = ({
  headerMode = 'light',
}: {
  headerMode?: HeaderMode
}) => {
  const { user, isUserLoading } = useUserContext()
  const showNav = !isUserLoading && !user
  const isLight = headerMode === 'light'
  return (
    <Box
      py={4}
      px={[6, 6, 12]}
      h={MARKETING_HEADER_HEIGHT}
      pr={[4, 6, 12]}
      zIndex="999"
      top={['auto', 'auto', '0']}
    >
      <Stack direction="row">
        <Link href="/" aria-current="page">
          <Image
            w="100px"
            src={
              isLight
                ? 'https://assets.website-files.com/62e823626cd4fdd0e5dc1527/62e823626cd4fdc26bdc1576_Gamma.svg'
                : gammaLogo.src
            }
            loading="lazy"
            alt="Gamma logo"
          />
        </Link>
        <Spacer />
        {showNav && (
          <Stack
            direction="row"
            spacing={[4, 4, 8]}
            fontWeight="500"
            fontSize="14px"
            color={isLight ? '#3b3a40' : '#fff'}
            alignItems="center"
            transition="color 300ms ease"
          >
            {HEADER_LINKS.map(({ text, url, hideWhenSmall }) => {
              return (
                <Link
                  key={text}
                  display={hideWhenSmall ? ['none', 'none', 'block'] : 'block'}
                  href={url}
                  color={isLight ? undefined : 'whiteAlpha.800'}
                  _hover={{
                    color: isLight ? '#000' : 'whiteAlpha.900',
                    textDecoration: 'none',
                  }}
                >
                  {text}
                </Link>
              )
            })}
            <Button
              as={Link}
              href={MARKETING_SITE_LINKS.signup}
              maxHeight="32px"
              borderStyle="solid"
              borderWidth="0.5px"
              fontSize="14px"
              borderColor="rgba(0, 0, 0, 0.24)"
              backgroundColor="#fff"
              boxShadow="0 4px 4px 0 rgb(0 0 0 / 5%)"
              fontWeight="700"
              borderRadius="100px"
              color="#3b3a40"
              _hover={{
                color: 'black',
                textDecoration: 'none',
              }}
            >
              Try for free
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
